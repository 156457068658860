<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 60%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="12">

            <b-col md="12">
              <b-form-group
                label="About Page :"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <label>

                  عن LPC</label>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="العنوان"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="العنوان"
                  rules="required"
                >
                  <b-form-input

                    v-model="addCourseForm.course"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="الوصف"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input

                    v-model="addCourseForm.course"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="الكلمات المفتاحية"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input

                    v-model="addCourseForm.course"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>

          </b-col>
        </b-row>
      </b-form>

    </validation-observer>
    <b-table
      ref="refCourseListTable"
      class="position-relative"

      responsive
      :fields="tableColumns"
      primary-key="id"

      striped

      show-empty
      empty-text="  يتم جلب البيانات"
    >

      <!-- Column: Status -->

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item :to="{ name: 'update-courses', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">تعديل</span>
          </b-dropdown-item>
          <b-dropdown-item-button @click="DeleteCourse(data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">حذف</span>
          </b-dropdown-item-button>

        </b-dropdown>
      </template>

    </b-table>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import store from '@/store'

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const coursesList = ref([])

    store.dispatch('categories/AllCategory')
      .then(response => {
        console.log('response', response.data.data)
        coursesList.value = response.data.data
      })
    const addCourseForm = reactive({
      is_free: '',
      name: '',
      link_id: '',
      tab_image: [],
      header_image: [],
      alt_tab_image: '',
      alt_header_image: '',
      duration: '',
      video_link: '',
      price: '',
    })
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      console.log(addCourseForm)
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('is_free', addCourseForm.is_free)
          formData.append('name', addCourseForm.name)
          formData.append('header_image', addCourseForm.header_image)
          formData.append('name', addCourseForm.name)
          formData.append('link_id', addCourseForm.link_id)
          formData.append('tab_image', addCourseForm.tab_image)
          formData.append(' header_image', addCourseForm.header_image)
          formData.append('alt_tab_image', addCourseForm.alt_tab_image)
          formData.append('alt_header_image', addCourseForm.alt_header_image)
          formData.append('duration', addCourseForm.duration)
          formData.append(' video_link', addCourseForm.video_link)
          formData.append('price', addCourseForm.price)
          console.log('formData', formData)
          store.dispatch('othercourses/addCourse', formData)
            .then(response => {
              Vue.swal({
                title: 'تم الاضافة بنجاح ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'حسنا',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

          console.log('alt_header_image', addCourseForm)
        }
      })
    }
    const tableColumns = [

      { key: 'id', label: 'ID' },
      { key: 'name', label: 'About Page ' },
      { label: 'الرابط', key: 'link_id' },
      { key: 'name', label: 'الوصف' },
      { label: 'KeyWords', key: 'التل' },

      { key: 'actions' },

    ]
    return {
      refInputEl,
      refPreviewEl,
      tableColumns,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BTable,
    ValidationProvider,
    ValidationObserver,

    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
